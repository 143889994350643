import { createTheme } from "@mui/material";

export default function getThemeOptions(mode) {
  var bgColor = "#fafafa";

  if (mode === "dark") {
    bgColor = "#212121";
  }

  return createTheme({
    palette: {
      mode: mode,
      primary: {
        main: "#1495F1",
        contrastText: "#FFF",
      },
      secondary: {
        main: "#0c79c6",
      },
      background: {
        default: bgColor,
      },
    },
    spacing: 8,
    shape: {
      borderRadius: 4,
    },
    props: {
      MuiAppBar: {
        color: "inherit",
      },
      MuiList: {
        dense: true,
      },
      MuiMenuItem: {
        dense: true,
      },
      MuiTable: {
        size: "small",
      },
      MuiButtonBase: {
        disableRipple: true,
      },
      MuiButton: {
        size: "small",
      },
      MuiButtonGroup: {
        size: "small",
      },
      MuiCheckbox: {
        size: "small",
      },
      MuiFab: {
        size: "small",
      },
      MuiFormControl: {
        margin: "dense",
        size: "small",
      },
      MuiFormHelperText: {
        margin: "dense",
      },
      MuiIconButton: {
        size: "small",
      },
      MuiInputBase: {
        margin: "dense",
      },
      MuiInputLabel: {
        margin: "dense",
      },
      MuiRadio: {
        size: "small",
      },
      MuiSwitch: {
        size: "small",
      },
      MuiTextField: {
        margin: "dense",
        size: "small",
      },
      MuiTooltip: {
        arrow: true,
      },
    },
  });
}
